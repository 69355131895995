
import { createVNode, defineComponent, onMounted, reactive, ref  } from 'vue';
import Pagination from '@/components/Pagination/index.vue'
import AddAvailabilityLock from '@/views/availabilityManagement/lockAvailability/addAvailabilityLock.vue';
import { _Client } from '@/api-client';
import { SearchAvailabilityLockPeriodInput, PageResultOfListOfAvailabilityLockPeriodDto } from '@/api-client/client';
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export default defineComponent({
    components: {
        Pagination,
        AddAvailabilityLock,
    },
  setup() {
    const columns = [
            {
                title: 'Lock Effective Date',
                dataIndex: 'lockEffectiveDateFormat',
            },
            {
                title: 'Lock Date',
                dataIndex: 'lockDateFormat',
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                width: 300
            }
        ];

    const dataSource = reactive<PageResultOfListOfAvailabilityLockPeriodDto>(new PageResultOfListOfAvailabilityLockPeriodDto());
    const searchParames = reactive<SearchAvailabilityLockPeriodInput>(new SearchAvailabilityLockPeriodInput({pageIndex: 1,pageSize:10}));
    const isShowAddAvailabilityLock = ref(false);
    const tableLoading = ref(false);

    const showAddAvailabilityLockModal = () => isShowAddAvailabilityLock.value = true;

    const closeAddAvailabilityLockModal = (isRefresh: boolean) => {
        isShowAddAvailabilityLock.value = false;
        if(isRefresh){
            getList();
        }
    }

    const showConfirm = (id: string) => {
      Modal.confirm({
        title: 'Do you want to delete this data? This data has already taken effect in the IEP Cancel Delete',
        icon: createVNode(ExclamationCircleOutlined),
        okText: "Delete",
        onOk() {
          _Client.availabilityLockPeriodClient.delete(id).then(rep => {
                message.success("success");
                getList();
            })
        },
      });
    };

    const getList = () => {
        tableLoading.value = true;
        _Client.availabilityLockPeriodClient.search(searchParames).then(rep => {
            dataSource.init(rep)
        }).finally(() => tableLoading.value = false)
    }

    onMounted(async () => {
        getList();
    })

    return {
        columns,
        isShowAddAvailabilityLock,
        dataSource,
        searchParames,
        tableLoading,
        getList,
        showConfirm,
        showAddAvailabilityLockModal,
        closeAddAvailabilityLockModal,
    }
  }
})
