
import { _Client } from '@/api-client';
import { AvailabilityLockPeriodInput } from '@/api-client/client';
import { FormInstance, RuleObject } from 'ant-design-vue/lib/form';
import { defineComponent, reactive, ref } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import { message } from 'ant-design-vue';

export default defineComponent({
    props: {
        onClose: {
            type: Function,
            required: true
        },
    },
    setup(props) {
        // 禁用过去日期的方法
        const disabledPastDate = (current: Dayjs) => {
            return current && current < dayjs().startOf('day');
        };
        const formRef = ref<FormInstance>();
        const formModel = ref<AvailabilityLockPeriodInput>();
        const formState = reactive({
            lockEffectiveDate: null,
            lockDate: null
        });
        const confirmLoading = ref(false);
        const lockEffectiveDate = ref<string | null>(null);
        const lockDate = ref<string | null>(null);

        const rules = {
            lockEffectiveDate: [
                {
                    required: true,
                    message: "请输入Lock Effective Date",
                    trigger: 'change'
                }
            ],
            lockDate: [
                {
                    required: true,
                    message: "请输入Lock Date",
                    trigger: 'change'
                }
            ],
        }

        const close = () => props.onClose(); // 调用传递给组件的 onClose 方法

        const submit = () => {
            formRef.value?.validate().then(() => {
                if (new Date(lockEffectiveDate.value!) >= new Date(lockDate.value!)) {
                    message.warning("Lock date should be later than lock effective date.");
                }
                else {
                    confirmLoading.value = true;
                    _Client.availabilityLockPeriodClient.add(new AvailabilityLockPeriodInput(
                        {
                            lockEffectiveDate: new Date(lockEffectiveDate.value!),
                            lockDate: new Date(lockDate.value!)
                        })).then(() => {
                            message.success("success");
                            props.onClose(true);
                        }).finally(() => confirmLoading.value = false);
                }
            })
        }

        const changelockEffectiveDate = (date: Dayjs, dateString: string) => {
            lockEffectiveDate.value = dateString;  // 单个日期的选择
        }
        const changeLockDate = (date: Dayjs, dateString: string) => {
            lockDate.value = dateString;  // 单个日期的选择
        }

        return {
            formModel,
            formState,
            formRef,
            rules,
            confirmLoading,
            close,
            submit,
            lockEffectiveDate,
            lockDate,
            changeLockDate,
            changelockEffectiveDate,
            disabledPastDate
        }

    },
})
